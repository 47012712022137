import React, { useRef } from 'react';
import './App.css';

function App() {
  const iframeRef = useRef(null);

  const enterFullscreen = () => {
    if (iframeRef.current) {
      if (iframeRef.current.requestFullscreen) {
        iframeRef.current.requestFullscreen();
      } else if (iframeRef.current.mozRequestFullScreen) {
        iframeRef.current.mozRequestFullScreen();
      } else if (iframeRef.current.webkitRequestFullscreen) {
        iframeRef.current.webkitRequestFullscreen();
      } else if (iframeRef.current.msRequestFullscreen) {
        iframeRef.current.msRequestFullscreen();
      }
    }
  };

  return (
    <div className="App">
      <div className="container_imagenes">
        <div className="grid-container">
            <a href="https://play.google.com/store/apps/details?id=com.rptv.app&pcampaignid=web_share">
              <img
                src="https://rath.mac-softins.com/assets/images/Google-Play-Simbolo.png"
                alt="Google Play"
                className="imgplaystore"
              />
            </a>
            <a href="https://livebethel.mac-softins.com/">
              <img
                src="https://radio-bethel.net/wp-content/uploads/2022/11/logo-bethel.png"
                alt="Google Play"
                className="imgplaystore"
              />
            </a>
        </div>
      </div>
      <div className="fullscreen-button-container">
        <button className="fullscreen-button" onClick={enterFullscreen}>
          Ver en Pantalla Completa
        </button>
      </div>
      <iframe
        ref={iframeRef}
        allowFullScreen
        src="https://vdo.grupolimalive.com/live-stream-video-widget/bethel"
        height="720px"
        width="100%"
        frameBorder="0"
        autoPlay
        title="Video en Vivo"
      ></iframe>
    </div>
  );
}

export default App;
